<template>
  <div style="margin-top: 180px">
    <div v-if="post">
      <v-container>
        <div>
          <v-speed-dial
            v-model="dialShare"
            fixed
            style="margin-top: 100px"
            right
            bottom
            direction="top"
            open-on-click
          >
            <template v-slot:activator>
              <v-btn fab bottom dark color="pink">
                <v-icon v-if="dialShare">mdi-close</v-icon>
                <v-icon v-else>mdi-share-variant</v-icon>
              </v-btn>
            </template>
            <v-btn
              dark
              fab
              top
              color="blue"
              small
              :href="`http://twitter.com/share?text=${post.title}&url=https://www.discursa.com.mx/blog/${post.slug}&hashtags=DISCURSA`"
              target="_blank"
            >
              <v-icon>mdi-twitter</v-icon>
            </v-btn>
            <v-btn
              dark
              fab
              top
              color="blue darken-3"
              small
              :href="`https://www.facebook.com/sharer/sharer.php?u=https://www.discursa.com.mx/blog/${post.slug} `"
              target="_blank"
            >
              <v-icon>mdi-facebook</v-icon>
            </v-btn>
            <v-btn
              dark
              fab
              top
              color="green"
              small
              :href="`https://wa.me/?text=Blog%20DISCURSA%20https://www.discursa.com.mx/blog/${post.slug}`"
              target="_blank"
            >
              <v-icon>mdi-whatsapp</v-icon>
            </v-btn>
          </v-speed-dial>
        </div>
        <h3 class="">{{ post.title }}</h3>
        <h5>{{ post.author }}</h5>
        <h6>{{ post.date }}</h6>
        <v-img class="my-10" max-height="300" contain :src="post.image"></v-img>
      </v-container>
      <v-container class="mt-4" v-html="post.content"> </v-container>
    </div>
    <v-container v-else class="text-center"> Cargando </v-container>
  </div>
</template>

<script>
import { db } from "@/plugins/firebase.js";
export default {
  data() {
    return {
      post: null,
      dialShare: false,
    };
  },
  mounted() {
    this.getPost();
  },
  methods: {
    async getPost() {
      try {
        let slug = await this.$route.params.name;
        const post = await db.collection("blog").where("slug", "==", slug).get();
        this.post = post.docs[0].data();
      } catch (error) {
        console.log(error);
        console.log(error.message);
      }
    },
  },
};
</script>
